import createReducer, { RESET_STORE } from "../createReducer";
import qs from "query-string";
import { uploadHelper } from "./uploadHelper";
import { getToken } from "./user";
import axios from "../config-axios";
import { message } from "antd";
import { SHORT_MESSAGE_DELAY } from "../constants";
export const SET_CHATBOT_FILES = "ai.SET_CHATBOT_FILES";
export const SET_CHAT_MESSAGES = "ai.ADD_MESSAGES";
export const CLEAR_CHAT_MESSAGES = "ai.CLEAR_CHAT_MESSAGES";
export const SET_LOADING_MESSAGES = "ai.SET_LOADING_MESSAGES";

export const setChatbotFiles =
  (obj) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { user } = getState().user;
    const { apiUrl } = getState().global;
    const payload = { email: user.email };
    const url = `${apiUrl}/ai/pineconelistfiles/`;

    return fetch(url, {
      method: "POST",
      token,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: {
        ...payload,
      },
      success: (res) => {
        const chatbotfiles = res.response;
        dispatch({ type: SET_CHATBOT_FILES, chatbotfiles });
      },
      failure: (err) => {
        //message.error("failed to find files", SHORT_MESSAGE_DELAY);
      },
    });
  };

export const clearMessages =
  (obj) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const { user } = getState().user;
    const { apiUrl } = getState().global;
    const payload = { email: user.email };
    const url = `${apiUrl}/ai/pineconeclearmessages/`;

    return fetch(url, {
      method: "DELETE",
      token,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: {
        ...payload,
      },
      success: (res) => {
        const chatbotfiles = res.response;
        dispatch({ type: CLEAR_CHAT_MESSAGES });
      },
      failure: (err) => {
        dispatch({ type: CLEAR_CHAT_MESSAGES });
      },
    });
  };

export const setMessages = (messages) => (dispatch) => {
  dispatch({ type: SET_CHAT_MESSAGES, messages });
};

export const setLoadingMessages = (isLoadingMessages) => (dispatch) => {
  dispatch({ type: SET_LOADING_MESSAGES, isLoadingMessages });
};

// ------------------------------------
// Reducer
// ------------------------------------
// in your ai reducer
const initialState = {
  chatbotfiles: {},
  messages: [{ type: "bot", content: "Hi! What would you like to get done today?" }],
  isLoadingMessages: false,
};

export default createReducer(initialState, {
  [SET_CHATBOT_FILES]: (state, { chatbotfiles }) => ({
    ...state,
    chatbotfiles,
  }),
  [SET_CHAT_MESSAGES]: (state, { messages }) => ({
    ...state,
    messages,
  }),
  [SET_LOADING_MESSAGES]: (state, { isLoadingMessages }) => ({
    ...state,
    isLoadingMessages,
  }),
  [CLEAR_CHAT_MESSAGES]: (state, {}) => ({
    ...state,
    messages: [{ type: "bot", content: "Hi! What would you like to get done today?" }],
  }),
  [SET_CHATBOT_FILES]: (state, { chatbotfiles }) => ({
    ...state,
    chatbotfiles,
  }),
});
