import createReducer, { RESET_STORE } from "../createReducer";
import { getToken, clearSessionCurrency } from "./user";
import qs from "query-string";
import _ from "lodash";
import { message } from "antd";

export const GET_SHOPPING_PRODUCT_SUCCESS = "ShopplingList.GET_SHOPPING_PRODUCT_SUCCESS";
export const GET_SHOPPING_PRODUCT_FAILURE = "ShopplingList.GET_SHOPPING_PRODUCT_FAILURE";
export const CLEAR_SHOPPING_PRODUCTS = "ShopplingList.CLEAR_SHOPPING_PRODUCTS";

export const getShoppingProducts =
  (params = {}) =>
  (dispatch, getState, { fetch }) => {
    const { token } = dispatch(getToken());
    const ids = params.ids ? params.ids : "";
    const qs = "id__in=" + ids;
    const url = `/pands/products/?${qs}`;
    return fetch(
      url,

      {
        method: "GET",
        token,
        success: (res) => {
          dispatch({ type: GET_SHOPPING_PRODUCT_SUCCESS, res });
        },

        // eslint-disable-next-line node/handle-callback-err
        failure: (err) => {
          dispatch({ type: GET_SHOPPING_PRODUCT_FAILURE });
        },
      }
    );
  };

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  shopping_products: [],
};

export default createReducer(initialState, {
  [GET_SHOPPING_PRODUCT_SUCCESS]: (state, { res: results }) => ({
    shopping_products: results,
  }),
  [CLEAR_SHOPPING_PRODUCTS]: (state, {}) => ({
    shopping_products: [],
  }),
  [GET_SHOPPING_PRODUCT_FAILURE]: (state, action) => ({
    shopping_products: [],
  }),
});
