import compact from "lodash/compact";
import _ from "lodash";
import { MASS_UNITS, RAWMATERIAL_UNITS } from "./constants";
import { notification } from "antd";
import history from "./history";
const cosmodeRegexTest = new RegExp("^/cosmode");
import { Tooltip } from "antd";
export function noop() {}
import "./global.css";

export function message() {}

const regex_cosmode = /cosmode/;

// this is normally done by _.compact but that only works on arrays not objects
// TBD try using _.omit
export const _removeFalsy = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop] && !_.isEmpty(obj[prop]) && obj[prop] != [""]) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};
export const removeFalsy = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (
      obj[prop] === true || // ✅ Keep boolean `true`
      (obj[prop] && !_.isEmpty(obj[prop]) && obj[prop] != [""])
    ) {
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const isCosmode = () => {
  if (process.env.BROWSER && cosmodeRegexTest.test(history.location.pathname)) {
    return true;
  }
};

export const addCategoryToName = (raw_ingredient) => {
  const ri = raw_ingredient;
  let name = ri.name;
  if (ri.category.length === 0) return;
  const regex = new RegExp("Bach|Gemstone Essence");
  const regexgem = new RegExp("Gemstone Essence");

  const catname = ri.category[0].name;
  if (regex.test(catname)) {
    if (regexgem.test(catname)) {
      name = `${ri.name} Gem Essence`;
    } else {
      name = `${ri.name} ${catname}`;
    }
  }
  return name;
};

// generic currency conversion
export const ccur = (obj, three_letter_currency) => {
  if (obj) {
    return obj.toLocaleString("en-US", {
      style: "currency",
      currency: three_letter_currency,
    });
  } else {
    return "";
  }
};

export const getFormErrors = ({ values, errors }) => {
  if (errors.non_field_errors) {
    // error on alert
    return { error: errors.non_field_errors && errors.non_field_errors[0] };
  } else {
    // errors on form fields
    const formErrors = {};
    const fields = Object.keys(errors);
    fields.forEach((field) => {
      formErrors[field] = {
        value: values[field],
        errors: [
          {
            field,
            message: errors[field][0],
          },
        ],
      };
    });
    return { formErrors };
  }
};

export const hasFeature = (user, featureName) => {
  if (!user || (user && !user.feature)) return false;
  const featureFound = user.feature.includes(featureName);
  return featureFound;
};

export const getFieldValue = (items) => compact(items).filter((item) => !Object.values(item).some((v) => v === undefined));

// eslint-disable-next-line prefer-rege`x-literals
const whitespace = new RegExp("^\\s+$|^$");

export const isEmpty = (value) => {
  if (value === undefined || value === null || whitespace.test(value) || value === [] || value === "") {
    return true;
  } else {
    return false;
  }
};

export const truncate = (str, n) => {
  if (str) {
    let copy = str.slice();
    return copy.length > n ? copy.substr(0, n - 1) + "..." : copy;
  }
};

export const rmitem = (arr, item) => {
  const idx = arr.indexOf(item);
  return arr.splice(idx, 1);
};

export const arrattrs = (arr, attr) => {
  const newarr = [];
  if (arr.length > 0) {
    for (let i = 0; i < arr.length; i++) {
      newarr.push(arr[i][attr]);
    }
  }
  return newarr;
};

// given an array of objects and an array of ids
//   get a list of the specified attr found in array_of_objects
export const arrObjAttr = (arr_of_objects, arr_of_ids, attr) => {
  let attrs = [];
  arr_of_ids.map((_id) => {
    const found = arr_of_objects.find((obj) => parseInt(obj.id) === parseInt(_id));
    if (found) attrs.push(found[attr]);
  });
  return attrs;
};

export const arrattrstr = (arr, attr) => {
  const filtered_arr = arrattrs(arr, attr);
  return filtered_arr ? filtered_arr.toString(", ") : "";
};

export const arrFilter = (arr, id) => {
  // remove item from array
  if (arr.includes(id)) {
    arr.splice(arr.indexOf(id), 1);
    // add item to an array
  } else {
    arr.push(id);
  }
  return arr;
};

export const arrRemoveItem = (arr, id) => {
  // remove item from array
  if (arr.includes(id)) {
    arr.splice(arr.indexOf(id), 1);
    // add item to an array
  }
  return arr;
};

export const reorderUnits = (arr, user_preferred_unit) => {
  // remove all the units found in the preferences
  let tmp_arr = [...arr].filter(function (el) {
    return user_preferred_unit.indexOf(el) < 0;
  });
  // remove any user specified preferences not found in the arr
  let modified_preferred_units = [...user_preferred_unit].filter(function (el) {
    return arr.indexOf(el) >= 0;
  });
  if (tmp_arr.length > 0) {
    return [...modified_preferred_units, ...tmp_arr];
  } else {
    return arr;
  }
};

export const getSelectUnits = (preferred_units) => {
  let mass_units = MASS_UNITS;
  let raw_material_units = RAWMATERIAL_UNITS;

  if (preferred_units && preferred_units.length > 0) {
    mass_units = reorderUnits(MASS_UNITS, preferred_units);
    raw_material_units = reorderUnits(RAWMATERIAL_UNITS, preferred_units);
  }

  return [mass_units, raw_material_units];
};

export const getRawIngredientUnits = (raw_ingredient, mass_units, raw_material_units) => {
  return raw_ingredient.mass_only ? mass_units : raw_material_units;
};


export const goToProduct = (item) => {
    // cosmetic can be either
    if (item && item.typ == "cosmetic")
        return false
    const typ = item.typ == "cosmode" ? "cosmode" : "product";
    const current_path = process.env.BROWSER && regex_cosmode.test(history.location.pathname) ? "cosmode" : "product";
    
    // only go to page if you are not on it. (aromatherapy or cosmode)
    if (item.typ != current_path) {
        const path = `/${typ}/${item.id}`;
        
        // Use window.location instead of history.push
        // This is a more forceful navigation method
        window.location.href = path;
        return true;
    }
    return true;
}
export const getProductIcon = (typ) => {
  let imgurl = undefined;
  let name = "";

  switch (typ) {
    case "cosmode":
      imgurl = "https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/createskincare.svg";
      name = "Skincare Blend";
      break;
    case "cosmetic":
      imgurl = "https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/svg/saveascos.svg";
      name = "Percentage Blend";
      break;
    case "aromatherapy":
      imgurl = "https://bpimg.sfo2.cdn.digitaloceanspaces.com/BP/icons/nav/createaromatherapy.svg";
      name = "Aromatherapy Blend";
      break;
    default:
      imgurl = "";
      name = "Unknown Blend";
  }

  return (
    <Tooltip title={name}>
      <img style={{ width: "20px", height: "20px" }} src={imgurl} alt={name} loading="lazy" />
    </Tooltip>
  );
};

import React from "react";
