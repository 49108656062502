import createReducer, { RESET_STORE } from "../createReducer";
import qs from "query-string";
import { uploadHelper } from "./uploadHelper";
import { getToken } from "./user";
import axios from "../config-axios";
import { message } from "antd";
import { SHORT_MESSAGE_DELAY } from "../constants";
export const SET_CHATBOT_FILES = "bpai.SET_CHATBOT_FILES";
export const SET_CHAT_MESSAGES = "bpai.ADD_MESSAGES";
export const CLEAR_CHAT_MESSAGES = "bpai.CLEAR_CHAT_MESSAGES";
export const SET_LOADING_MESSAGES = "bpai.SET_LOADING_MESSAGES";

export const clearBPMessages =
  (obj) =>
  (dispatch, getState, { fetch }) => {
    const { user } = getState().user;

    if (!user) {
      // If no user, just clear the messages locally
      dispatch({ type: CLEAR_CHAT_MESSAGES });
      return;
    }

    const { token } = dispatch(getToken());
    const payload = { email: user.email };
    const { apiUrl } = getState().global;
    const url = `${apiUrl}/ai/bppineconeclearmessages/`;

    return fetch(url, {
      method: "DELETE",
      token,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: {
        ...payload,
      },
      success: (res) => {
        const chatbotfiles = res.response;
        dispatch({ type: CLEAR_CHAT_MESSAGES });
      },
      failure: (err) => {
        dispatch({ type: CLEAR_CHAT_MESSAGES });
        //        message.error("failed to find files", SHORT_MESSAGE_DELAY);
      },
    });
  };

export const setBPMessages = (bpmessages) => (dispatch) => {
  dispatch({ type: SET_CHAT_MESSAGES, bpmessages });
};

export const setBPLoadingMessages = (bpisLoadingMessages) => (dispatch) => {
  dispatch({ type: SET_LOADING_MESSAGES, bpisLoadingMessages });
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  bpmessages: [
    {
      type: "bot",
      content: "Hi! I'm your Blend Precisely Assistant. I hold over 400 recipes and over 120 GCMS reports. Start prompting me!",
    },
  ],
  bpisLoadingMessages: false,
};

export default createReducer(initialState, {
  [SET_CHAT_MESSAGES]: (state, { bpmessages }) => ({
    ...state,
    bpmessages,
  }),
  [SET_LOADING_MESSAGES]: (state, { bpisLoadingMessages }) => ({
    ...state,
    bpisLoadingMessages,
  }),
  [CLEAR_CHAT_MESSAGES]: (state, {}) => ({
    ...state,
    bpmessages: [
      {
        type: "bot",
        content: "Hi! I'm your Blend Precisely Assistant. I hold over 400 recipes and over 120 GCMS reports. Start prompting me!",
      },
    ],
  }),
});
